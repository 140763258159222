var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',{attrs:{"color":"error","large":"","to":_vm.$_getRouteGreatGrandChildDelete(
      _vm.role,
      _vm.model.key,
      _vm.$route.params.id,
      _vm.me,
      _vm.modelParent.key,
      _vm.$route.params.parentId,
      _vm.parent,
      _vm.modelGrandParent.key,
      _vm.grandParent.id,
      _vm.grandParent,
      _vm.modelGreatGrandParent.key,
      _vm.greatGrandParent.id,
      _vm.greatGrandParent
    )}},[_vm._v(" "+_vm._s(_vm.$t("action.delete"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }