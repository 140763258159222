var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","large":"","to":{
            name: 'PageModeratorBookChapterChapterQuestionChapterQuestionOptionAdd',
            params: {
              parentId: _vm.parent.id,
              parent: _vm.parent,
              grandParentId: _vm.grandParent.id,
              grandParent: _vm.grandParent,
              greatGrandParentId : _vm.greatGrandParent.id,
              greatGrandParent: _vm.greatGrandParent,
            },
          }}},[_vm._v(" + New Option ")])],1)],1),_c('BreadBrowseTable',{attrs:{"role":"Moderator","model":_vm.model,"url":((_vm.$api.servers.game) + "/api/v1/en/moderator/book/" + (_vm.$route.params.parentId) + "/chapter/" + (_vm.$route.params.grandParentId) + "/question/" + (_vm.parent.id) + "/option"),"isSearchable":true,"parent":_vm.parent,"modelParent":_vm.modelParent,"grandParent":_vm.grandParent,"modelGrandParent":_vm.modelGrandParent,"greatGrandParent":_vm.greatGrandParent,"modelGreatGrandParent":_vm.modelGreatGrandParent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }